import { kebabCase, startCase, toLower } from 'lodash-es'

export default {
  urlParams: (str: string) => {
    // remove non alphanumeric characters
    str = str.replace(/[^\w\s]+/gm, '')
    // convert to kebab case
    str = kebabCase(str)

    // trim words if greater than length threshold
    const maxLength = 100
    while (str.length > maxLength) {
      const words = str.split('-').filter(word => word)
      words.pop()
      str = words.join('-')
    }

    return str
  },
  titleCase: (str: string) => {
    return startCase(toLower(str))
  },
}