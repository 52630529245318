import {
  format,
} from 'date-fns/esm'
import { id } from 'date-fns/esm/locale'

export default {
  date: (dt: Date | string | null, fmt: string) => {
    if (dt === null) {
      return '-'
    }
    return format(new Date(dt), fmt, { locale: id })
  },
}