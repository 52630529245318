import DateFormatter from './formatters/date'
import NumberFormatter from './formatters/number'
import StorageFormatter from './formatters/storage'
import StringFormatter from './formatters/string'

export const stringContains = (haystack: string, needles: string[]) => {
  return needles.some(word => haystack.includes(word))
}

export const fmt = {
  ...DateFormatter,
  ...NumberFormatter,
  ...StorageFormatter,
  ...StringFormatter,
}
